import type React from "react";
import { useState } from "react";

import AudienceDemography from "@/components/analytics/audience-demography";
import ContentAnalysis from "@/components/analytics/content-analysis";
import type { TCreatorPerformance } from "@/components/analytics/creator-performance";
import CreatorPerformance from "@/components/analytics/creator-performance";
import type { TCreatorSummary } from "@/components/analytics/creator-summary";
import CreatorSummary from "@/components/analytics/creator-summary";
import PostContent from "@/components/analytics/post-content";
import SectionOverlay from "@/components/ui/section-overlay";
import type { TikTokComplete, TikTokPublic } from "@/types/tiktok";

type TikTokProfileProps = {
	profileData: TikTokPublic | TikTokComplete;
	overlay: boolean;
	displayType?: "public" | "analytics" | "er_calc";
	analyzeInfluencer: (username: string) => void;
	isDummy?: boolean;
};

const TikTokProfile: React.FC<TikTokProfileProps> = ({
	profileData,
	overlay,
	displayType = "public",
	analyzeInfluencer,
	isDummy = false,
}) => {
	const [isAnalyzing, setIsAnalyzing] = useState(false);

	let summaryData: TCreatorSummary;

	let performanceData: TCreatorPerformance;

	let ageData, locationData, genderData, interestData, posts;
	if (displayType === "analytics") {
		const tiktokComplete = profileData as TikTokComplete;
		summaryData = {
			...tiktokComplete.tiktokMetric,
			post: tiktokComplete.tiktokMetric.postCount,
			avgErPercent: tiktokComplete.tiktokMetric.engagementRate,
			city: tiktokComplete.tiktokMetric.tiktokEstimatedMetric.city,
			province: tiktokComplete.tiktokMetric.tiktokEstimatedMetric.province,
			country: tiktokComplete.tiktokMetric.tiktokEstimatedMetric.country,
			gender: tiktokComplete.tiktokMetric.tiktokEstimatedMetric.gender,
		};
		performanceData = {
			...tiktokComplete.tiktokMetric,
			engagement: tiktokComplete.tiktokMetric.avgEngagement,
			avgComments: tiktokComplete.tiktokMetric.avgComments,
			avgLikes: tiktokComplete.tiktokMetric.avgLikes,
			avgViews: tiktokComplete.tiktokMetric.avgViews,
		};

		ageData =
			tiktokComplete.tiktokMetric.tiktokEstimatedMetric.audienceAges.map(
				(x) => x.value
			);
		locationData =
			tiktokComplete.tiktokMetric.tiktokEstimatedMetric.audienceLocations;
		genderData = {
			male: tiktokComplete.tiktokMetric.tiktokEstimatedMetric
				.audienceGenderMale,
			female:
				tiktokComplete.tiktokMetric.tiktokEstimatedMetric.audienceGenderFemale,
		};
		interestData = tiktokComplete.tiktokMetric.tiktokEstimatedMetric.interests;

		posts =
			tiktokComplete.tiktokMetric.tiktokPost.map((post) => ({
				...post,
				mediaThumbnail: post.coverImageUrl,
			})) ?? [];
		performanceData.postCount = posts.length;
		performanceData.videoCount = posts.length;
	} else if (displayType === "er_calc") {
		const tiktokComplete = profileData as TikTokComplete;
		summaryData = {
			...tiktokComplete.tiktokMetric,
			post: tiktokComplete.tiktokMetric.postCount,
			avgErPercent: tiktokComplete.tiktokMetric.engagementRate,
			city: "Jakarta Selatan",
			province: "DKI Jakarta",
			country: "Indonesia",
			gender: "Male",
		};
		performanceData = {
			...tiktokComplete.tiktokMetric,
			engagement: tiktokComplete.tiktokMetric.avgEngagement,
			avgComments: tiktokComplete.tiktokMetric.avgComments,
			avgLikes: tiktokComplete.tiktokMetric.avgLikes,
			avgViews: tiktokComplete.tiktokMetric.avgViews,
		};
		performanceData.postCount = tiktokComplete.tiktokMetric.tiktokPost.length;
		performanceData.videoCount = tiktokComplete.tiktokMetric.tiktokPost.length;
	} else {
		summaryData = {
			...(profileData as TikTokPublic),
			post: (profileData as TikTokPublic).postCount,
			avgErPercent: (profileData as TikTokPublic).engagementRate,
			city: "Jakarta Selatan",
			province: "DKI Jakarta",
			country: "Indonesia",
			gender: "Male",
		};
		performanceData = {
			...(profileData as TikTokPublic),
			engagement: (profileData as TikTokPublic).avgEngagement,
			avgComments: (profileData as TikTokPublic).avgComments,
			avgLikes: (profileData as TikTokPublic).avgLikes,
			avgViews: (profileData as TikTokPublic).avgViews,
		};
	}
	return (
		<div className="flex flex-col justify-start items-center my-4">
			<div className="flex flex-col max-w-screen-2xl w-full my-4">
				<CreatorSummary
					profileData={summaryData}
					blur={displayType == "public"}
					platform="tiktok"
					isDummy={isDummy}
				></CreatorSummary>
			</div>

			<div className="mt-10 flex justify-center w-full max-w-screen-2xl bg-grey-100">
				{/* Content */}
				<div className="flex flex-col gap-10 py-10 px-10 w-full max-w-screen-2xl">
					<div className="relative flex flex-col gap-4 max-w-screen-2xl ">
						{/* Creator Performance */}
						<CreatorPerformance
							profileData={performanceData}
							isDummy={isDummy}
							platform="tiktok"
						></CreatorPerformance>
					</div>

					<SectionOverlay
						overlay={overlay}
						isAnalyzing={isAnalyzing}
						setIsAnalyzing={setIsAnalyzing}
						displayType={displayType}
						analyzeInfluencer={() => {
							analyzeInfluencer(summaryData.username);
						}}
					>
						{/* Audience Demography */}
						<AudienceDemography
							ageData={ageData}
							locationData={locationData}
							genderData={genderData}
						></AudienceDemography>
						{/* Content Analysis */}
						<div className="flex flex-row md:flex-row gap-4 my-4 flex-wrap">
							<div className="flex w-full lg:w-[calc(33.33%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-4 bg-white rounded-lg">
								<ContentAnalysis interests={interestData}></ContentAnalysis>
							</div>
							<div className="flex w-full lg:w-[calc(66.66%-1rem)] md:w-[calc(50%-1rem)] sm:w-full p-4 bg-white rounded-lg">
								<PostContent
									posts={posts}
									platform="tiktok"
									username={summaryData.username}
								></PostContent>
							</div>
						</div>
					</SectionOverlay>
					{/* Top Content */}

					{/* Creator Lookalike */}
					{/* <CreatorLookalike></CreatorLookalike> */}
				</div>
			</div>
		</div>
	);
};

export default TikTokProfile;
